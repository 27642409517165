import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { DefaultSeo } from 'next-seo';
import { GlobalProvider } from '@/context/useGlobalContext';
import ErrorBoundary from '@/components/Common/ErrorBoundary';
import Head from 'next/head';


export default function App({ Component, router, pageProps: { session, ...pageProps}, }: AppProps) {
  return (
    <>
      <DefaultSeo
        title='MOOIMOM: Beli & Konsultasi Online Perlengkapan Bayi & Ibu'
        titleTemplate='%s'
        description='Baru! Belanja perlengkapan bayi & ibu online sambil konsultasi permasalahan ibu dan bayi. FREE Biaya Konsultasi. Free Ongkir.'
        openGraph={{
          type: 'website',
          locale: 'id-ID',
          url: process.env.NEXT_PUBLIC_HOSTNAME,
          siteName: 'MOOIMOM website',
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.png'
          }
        ]}
      />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {process.env.NODE_ENV === 'production' && (
        <GoogleAnalytics
          strategy='lazyOnload'
          trackPageViews={{ ignoreHashChange: true }}
        />
      )}
      <SessionProvider session={pageProps.session}>
        <GlobalProvider>
          <ErrorBoundary>
            <Component {...pageProps} key={router.asPath} />
          </ErrorBoundary>
        </GlobalProvider>
      </SessionProvider>
    </>
  )
}
